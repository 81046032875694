<template>
    <div>
      <div ref="map" v-show="!isLoadingMap" style="height: 235px;"></div>
      <v-skeleton-loader
        min-height="235"
        type="image"
        loading
        v-show="isLoadingMap"
      ></v-skeleton-loader>
    </div>
  </template>
  
  <script>
  

  export default {
    name: "LeafletMap",
    props: ["destination", "origin", "position"],
    data() {
      return {
        map: null,
        isLoadingMap: true
      };
    },
    methods: {
      configMap() {
        this.map = window.L.map(this.$refs["map"]).setView([this.origin.lat, this.origin.lng], 12);
        window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 19,
			attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
		}).addTo(this.map);
  
        window.L.layerGroup().addTo(this.map);
        const bounds = window.L.latLngBounds();
        if (this.position) {
          this.setMaker(this.position, "TU");
        } else {
          this.setMaker(this.origin, "A");
          bounds.extend(this.origin);

          this.setMaker(this.destination, "B");
          setTimeout(() => {
            bounds.extend(this.destination);
          }, 10);
          // Uncomment below if you want to display directions
          // this.displayDirections();
        }
       
        // Create an array of marker coordinates
        
        // Create a bounds object from the marker coordinates
        

        // Fit the map to the bounds with some padding
        
        setTimeout(()=>{
            this.map.invalidateSize();
            this.map.fitBounds(bounds.pad(0.3));
        }, 10);
        this.isLoadingMap = false;
        
    },
      initMap() {
        this.configMap();
      },
      setMaker(Points, Label) {
        window.L.marker([Points.lat, Points.lng], {
          icon: window.L.divIcon({
            className: 'leaflet-div-icon',
            html: `<span style="background-color: #FF0000; color: #FFF; padding: 5px 10px; border-radius: 50%;">${Label}</span>`,
          }),
        }).addTo(this.map);
      },
      // Uncomment below if you want to display directions
      // displayDirections() {
      //   let dS = L.Routing.control({
      //     waypoints: [
      //       L.latLng(this.origin.lat, this.origin.lng),
      //       L.latLng(this.destination.lat, this.destination.lng)
      //     ],
      //     routeWhileDragging: true
      //   }).addTo(this.map);
      // }
    },
    mounted() {
      this.initMap();
    },
  };
  </script>
  
  <style scoped>
  #map {
    height: 235px;
  }
  </style>
  